.flexRow {
  text-align: center;
  display: flex;
  width: 100%;
}

.circleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
}

.outerCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px 0;
  position: relative;
  background-color: #dcdcdc;
}

.innerCircle {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: grey;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.innerCircleGreen {
  background-color: #0ebc93;
}

.innerCircleGrey {
  background-color: #bfbfbf;
}

.line {
  width: 5px;
  height: 112px;
  background-color: #dcdcdc;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.data {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
}
