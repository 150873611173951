.mapboxgl-popup-content {
  background-color: rgba(26, 26, 35, 0.8);
  color: white;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
}

.mapboxgl-popup-close-button {
  right: 4px;
}

.mapboxgl-popup-tip {
  border-top-color: rgba(26, 26, 35, 0.8) !important;
}
