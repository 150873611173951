.oui-popover__content {
  @apply data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade 
  data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade 
  data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade 
  data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade 
  text-white select-none 
  rounded-lg bg-[#1E253F] p-4 text-sm leading-none 
  shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] 
  will-change-[transform,opacity];
}

div:has(div.oui-popover__content) {
  @apply !z-[99] !top-2;
}

.oui-popover__arrow {
  @apply fill-[#1E253F];
}
