.facility-wrapper {
    display: flex;
    flex-direction: column;
    height: 90vh;
}

.facility-wrapper .oui-scrollArea,
.facility-wrapper .oui-scrollArea .oui-modal__body,
.facility-wrapper .oui-scrollArea .oui-modal__body>div {
    display: flex !important;
    flex-direction: column;
    height: 100%;
    flex: 1;
}

.facility-wrapper .oui-scrollArea .oui-modal__body {
    max-height: 100%;
}



.facility-map .mapboxgl-ctrl-logo, .mapboxgl-ctrl {
    display: none !important;
}

.facility-map .mapboxgl-popup {
    max-width: max-content !important;
}

.facility-map .mapboxgl-popup-content {
    background: #21232E;
    border-radius: 12px;
}

.facility-map .mapboxgl-popup-close-button {
    display: none;
}

.facility-map .mapboxgl-popup-tip {
    display: none;
}

.scrollBox::-webkit-scrollbar {
    height: 2px;
}

.scrollBox::-webkit-scrollbar-track {
    background: #85869833;
    border-radius: 2px;
}

.scrollBox::-webkit-scrollbar-thumb {
    border-radius: 2px;
    border: 1px solid #85869833;
    background: #292a35cc;
}

.oui-button--primary {
    @apply text-[#EBEBEB] bg-gradient-to-r from-[#3BA0E6] to-[#3B6EF3];
  }