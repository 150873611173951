.nav {
  position: sticky;
  top: var(--header-height);
  z-index: calc(var(--header-z-index) - 1);
  /* padding: 0 16px; */
  border-bottom: 1px solid rgba(27, 22, 1, 0.19);
  /* background-color: #191921; */
}

.navList {
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  align-items: center;
  margin-bottom: -1px;
  gap: 16px;
  /* position: relative; */
}

/* .navList::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 2rem;
    background-image: linear-gradient(to right, transparent, var(--app-foreground));
  } */

.navList li {
  flex: 0 0 auto;
}

.links {
  display: block;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  background: transparent;
  padding: 16px 0 10px;
  border-bottom: 4px solid transparent;
  transition:
    background-color 0.1s ease,
    color 0.1s ease;
}

/* Hover Effect */
.links:hover,
.links:focus-visible {
  border-bottom: 4px solid #3ba0e6;
  transition: 0.5s;

  /* background-color: var(--navigation-bg-hover); */
}

.links.active {
  /* border-bottom-color: currentColor; */
  /* border-bottom: 4px solid;
    border-image-source: linear-gradient(96.24deg, #3ba0e6 0%, #3b6ef3 100%); */
  border-bottom: 4px solid #3ba0e6;
}
