.filterPanel {
  background: linear-gradient(180deg, #043155 0%, #001d34 100%);
  transition: transform 0.3s ease-in-out;
}

.filterTitle {
  font-size: 20px;
  font-weight: 700;
}

.subFilterTitle {
  font-size: 18px;
  font-weight: 600;
}

.drawerBody {
  padding: 0 !important;
  overflow: unset !important;
}

.drawerHeader {
  padding: 37px 48px 21px 30px !important;
  border-bottom: 1px solid #d9d9e080;
}

.drawerHeader button {
  top: 20px;
  right: 20px;
}
