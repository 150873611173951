body {
	background-color: #000 !important;
	height: 100vh;
	padding-bottom: 5vh;
	color: #fff;
	width: 100vw;
	min-width: 100vw;
	overflow-x: auto;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0px 1000px black inset !important;
	box-shadow: 0 0 0px 1000px black inset !important;
	-webkit-text-fill-color: white !important;
}

input[type="text"] {
	-webkit-appearance: none; /* Remove default mobile styling */
	-moz-appearance: none;
	appearance: none;
	border-radius: 0; /* Ensures no rounding on mobile */
}

#tracking {
	margin: 0em auto 0rem auto;
	color: #fff;
}

#tracking-logged-in {
	margin: -1em auto 2rem auto;
	color: #fff;
}

#tracking .row_2_col,
#tracking-logged-in .row_2_col {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 5rem;
	align-items: center;
}
#tracking .row_2_col h2,
#tracking-logged-in .row_2_col h2 {
	font-size: 2.3rem;
	font-weight: 300;
}
#tracking .row_2_col img,
#tracking-logged-in .row_2_col img {
	display: block;
	margin: auto;
}

#tracking .tracking {
	max-width: 100%;
	margin: -1rem auto 0rem auto !important;
	text-align: center;
}

#tracking-logged-in .tracking {
	max-width: 100%;
	margin: 2rem auto 1rem auto;
	text-align: center;
}
#tracking .tracking > p,
#tracking-logged-in .tracking > p {
	font-size: 1.3rem;
}
#tracking .tracking .form_group,
#tracking-logged-in .tracking .form_group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 2.5rem 2rem;
	max-width: 90%;
}
#tracking .tracking .form_group label,
#tracking-logged-in .tracking .form_group label {
	text-align: left;
	font-size: 0.88rem;
}
#tracking .tracking .form_group input,
#tracking-logged-in .tracking .form_group input {
	width: 100%;
	padding: 1rem;
	margin-top: 0.5rem;
	border: 1px solid #fff;
	border-radius: 5px;
	background: none;
	color: #fff;
}
#tracking .tracking_details .card,
#tracking-logged-in .tracking_details .card {
	background: rgba(0, 0, 0, 0.3);
	padding: 0.5rem 5rem;
	margin-top: 5rem;
	border-radius: 5px;
}
#tracking .tracking_details .card .top_row,
#tracking-logged-in .tracking_details .card .top_row {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
#tracking .tracking_details .card .top_row .toggle_view,
#tracking-logged-in .tracking_details .card .top_row .toggle_view {
	background: rgba(133, 134, 152, 0.2);
	padding: 0.5rem;
	border-radius: 5px;
	display: flex;
}
#tracking .tracking_details .card .top_row .toggle_view button,
#tracking-logged-in .tracking_details .card .top_row .toggle_view button {
	border: none;
	background: none;
	color: rgba(235, 235, 235, 0.8);
	padding: 0.5rem 1rem;
	min-width: 7rem;
	border-radius: 6px;
	cursor: pointer;
}
#tracking .tracking_details .card .top_row .toggle_view button:hover,
#tracking-logged-in .tracking_details .card .top_row .toggle_view button:hover {
	color: #fff;
}
#tracking .tracking_details .card .top_row .toggle_view button.active,
#tracking-logged-in
	.tracking_details
	.card
	.top_row
	.toggle_view
	button.active {
	background: #2294ff;
	color: #fff;
}
#tracking .tracking_details .card h4,
#tracking-logged-in .tracking_details .card h4 {
	font-weight: 600;
	font-size: 1.22rem;
}
#tracking .tracking_details .card h4 .datetime,
#tracking-logged-in .tracking_details .card h4 .datetime {
	color: #faa808;
}
#tracking .tracking_details .card .track_bar,
#tracking-logged-in .tracking_details .card .track_bar {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 4rem 0 6rem;
}
#tracking .tracking_details .card .track_bar .bar,
#tracking-logged-in .tracking_details .card .track_bar .bar {
	height: 20px;
	width: calc(100% - 40px);
	background: rgba(133, 134, 152, 0.2);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
#tracking .tracking_details .card .track_bar .bar .fill,
#tracking-logged-in .tracking_details .card .track_bar .bar .fill {
	display: flex;
	width: 100%;
	height: 100%;
}
#tracking .tracking_details .card .track_bar .bar .fill .fill-section,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.bar
	.fill
	.fill-section {
	height: 100%;
}
#tracking .tracking_details .card .track_bar .bar .fill .fill-section.green,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.bar
	.fill
	.fill-section.green {
	width: 25%;
	background: #008080;
}
#tracking .tracking_details .card .track_bar .bar .fill .fill-section.red,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.bar
	.fill
	.fill-section.red {
	width: 25%;
	background: #d40511;
}
#tracking .tracking_details .card .track_bar .bar .fill .fill-section.empty,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.bar
	.fill
	.fill-section.empty {
	width: 50%;
	background: transparent;
}
#tracking .tracking_details .card .track_bar .slider,
#tracking-logged-in .tracking_details .card .track_bar .slider {
	height: 20px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}
#tracking .tracking_details .card .track_bar .slider.grey,
#tracking-logged-in .tracking_details .card .track_bar .slider.grey {
	background: #858698;
}
#tracking .tracking_details .card .track_bar .slider.green,
#tracking-logged-in .tracking_details .card .track_bar .slider.green {
	background: #008080;
}
#tracking .tracking_details .card .track_bar .slider.red,
#tracking-logged-in .tracking_details .card .track_bar .slider.red {
	background: #d40511;
}
#tracking .tracking_details .card .track_bar .point,
#tracking-logged-in .tracking_details .card .track_bar .point {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: #858698;
	z-index: 2;
	position: relative;
}
#tracking .tracking_details .card .track_bar .point.red,
#tracking-logged-in .tracking_details .card .track_bar .point.red {
	background: #d40511;
}
#tracking .tracking_details .card .track_bar .point .label,
#tracking-logged-in .tracking_details .card .track_bar .point .label {
	position: absolute;
	top: calc(100% + 1rem);
	left: 0;
	width: max-content;
}
#tracking .tracking_details .card .track_bar .point .label p,
#tracking-logged-in .tracking_details .card .track_bar .point .label p {
	margin: 0;
	font-size: 0.8rem;
	color: #7d8488;
}
#tracking .tracking_details .card .track_bar .point.green,
#tracking-logged-in .tracking_details .card .track_bar .point.green {
	background: #008080;
}
#tracking .tracking_details .card .track_bar .point.red,
#tracking-logged-in .tracking_details .card .track_bar .point.red {
	background: #d40511;
}
#tracking .tracking_details .card .track_bar .point.completed,
#tracking-logged-in .tracking_details .card .track_bar .point.completed {
	box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.4),
		-2px -2px 4px 0px rgba(0, 0, 0, 0.2509803922);
	animation: completed 0.5s ease forwards;
}
#tracking .tracking_details .card .track_bar .point.completed::after,
#tracking-logged-in .tracking_details .card .track_bar .point.completed::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-image: url(../../public/images/tracking/tick.svg);
	background-repeat: no-repeat;
	background-position: center;
	transform: scale(0);
	animation: tick-scale-anim 0.5s ease forwards;
}
@keyframes tick-scale-anim {
	to {
		transform: scale(1);
	}
}
#tracking .tracking_details .card .track_bar .point.completed .label p,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point.completed
	.label
	p {
	animation: color-anim 0.4s ease forwards;
}
@keyframes color-anim {
	to {
		color: #fff;
	}
}
@keyframes completed {
	to {
		background: #008080;
		box-shadow: 0 0 0 5px #80c0bf inset, 2px 2px 2px 0px rgba(0, 0, 0, 0.4),
			-2px -2px 4px 0px rgba(0, 0, 0, 0.2509803922);
	}
}
#tracking .tracking_details .card .track_bar .point.red-cross,
#tracking-logged-in .tracking_details .card .track_bar .point.red-cross {
	background: #d40511;
	box-shadow: 0 0 0 5px #f4676e inset, 2px 2px 2px 0px rgba(0, 0, 0, 0.4),
		-2px -2px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
#tracking .tracking_details .card .track_bar .point.red-cross::after,
#tracking-logged-in .tracking_details .card .track_bar .point.red-cross::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-image: url(../../public/Close_MD.svg);
	background-repeat: no-repeat;
	background-position: center;
}
#tracking .tracking_details .card .track_bar .point.latest_point::before,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point.latest_point::before {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 0;
	height: 100%;
	width: 100%;
	background-image: url(../../public/images/tracking/truck.svg);
	background-repeat: no-repeat;
	background-position: center;
	transform: translateY(100%);
	opacity: 0;
	animation: fade-up 0.4s ease forwards;
}
@keyframes fade-up {
	to {
		transform: translateY(0);
		opacity: 1;
	}
}
#tracking .tracking_details .card .track_bar .point[data-point="2"],
#tracking-logged-in .tracking_details .card .track_bar .point[data-point="2"] {
	animation-delay: 0.4s;
}
#tracking .tracking_details .card .track_bar .point[data-point="2"]::after,
#tracking .tracking_details .card .track_bar .point[data-point="2"]::before,
#tracking .tracking_details .card .track_bar .point[data-point="2"] .label p,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="2"]::after,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="2"]::before,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="2"]
	.label
	p {
	animation-delay: 0.4s;
}
#tracking .tracking_details .card .track_bar .point[data-point="3"],
#tracking-logged-in .tracking_details .card .track_bar .point[data-point="3"] {
	animation-delay: 0.8s;
}
#tracking .tracking_details .card .track_bar .point[data-point="3"]::after,
#tracking .tracking_details .card .track_bar .point[data-point="3"]::before,
#tracking .tracking_details .card .track_bar .point[data-point="3"] .label p,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="3"]::after,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="3"]::before,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="3"]
	.label
	p {
	animation-delay: 0.8s;
}
#tracking .tracking_details .card .track_bar .point[data-point="4"],
#tracking-logged-in .tracking_details .card .track_bar .point[data-point="4"] {
	animation-delay: 1.3s;
}
#tracking .tracking_details .card .track_bar .point[data-point="4"]::after,
#tracking .tracking_details .card .track_bar .point[data-point="4"]::before,
#tracking .tracking_details .card .track_bar .point[data-point="4"] .label p,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="4"]::after,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="4"]::before,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="4"]
	.label
	p {
	animation-delay: 1.3s;
}
#tracking .tracking_details .card .track_bar .point[data-point="5"],
#tracking-logged-in .tracking_details .card .track_bar .point[data-point="5"] {
	animation-delay: 1.7s;
}
#tracking .tracking_details .card .track_bar .point[data-point="5"]::after,
#tracking .tracking_details .card .track_bar .point[data-point="5"]::before,
#tracking .tracking_details .card .track_bar .point[data-point="5"] .label p,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="5"]::after,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="5"]::before,
#tracking-logged-in
	.tracking_details
	.card
	.track_bar
	.point[data-point="5"]
	.label
	p {
	animation-delay: 1.7s;
}
#tracking .linear_view > h5,
#tracking-logged-in .linear_view > h5 {
	margin: 3rem 0 2rem;
}
#tracking .linear_view h5,
#tracking-logged-in .linear_view h5 {
	font-size: 0.88rem;
}
#tracking .linear_view .event,
#tracking-logged-in .linear_view .event {
	display: grid;
	grid-template-columns: auto auto 1fr;
	gap: 2rem;
	margin-top: 4rem;
}
#tracking .linear_view .event .point,
#tracking-logged-in .linear_view .event .point {
	height: 24px;
	width: 24px;
	border-radius: 50%;
	position: relative;
	z-index: 100;
}
#tracking .linear_view .event .point::before,
#tracking-logged-in .linear_view .event .point::before {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	height: 8.4rem;
	width: 1px;
	background: var(--line-color, rgba(133, 134, 152, 0.2));
	z-index: 1;
}
#tracking .linear_view .event .datetime,
#tracking-logged-in .linear_view .event .datetime {
	font-size: 0.8rem;
	margin-right: 2rem;
}
#tracking .linear_view .event .details h5,
#tracking-logged-in .linear_view .event .details h5 {
	margin: 0 0 1rem;
}
#tracking .linear_view .event .details .grid_2_col,
#tracking-logged-in .linear_view .event .details .grid_2_col {
	display: grid;
	grid-template-columns: 14rem auto;
}
#tracking .linear_view .event .details .grid_2_col p,
#tracking-logged-in .linear_view .event .details .grid_2_col p {
	color: #b6b6b6;
	margin: 0;
	font-size: 0.8rem;
}
#tracking .linear_view .event:first-of-type,
#tracking-logged-in .linear_view .event:first-of-type {
	margin-top: 0;
}
#tracking .linear_view .event:first-of-type .point::before,
#tracking-logged-in .linear_view .event:first-of-type .point::before {
	display: none;
}
#tracking-logged-in {
	max-width: none;
	margin: 0;
	overflow-x: hidden;
}
#tracking-logged-in .linear_view,
#tracking-logged-in .map_view {
	margin-bottom: 3rem;
}
#tracking-logged-in .linear_view {
	margin-left: 2rem;
}
@media (max-width: 800px) {
	#tracking,
	#tracking-logged-in {
		max-width: 100%;
	}
	#tracking .tracking_details .card,
	#tracking-logged-in .tracking_details .card {
		padding: 0.5rem 0.5rem;
		padding: 0.5rem 0.5rem;
	}
	#tracking .tracking,
	#tracking-logged-in .tracking {
		width: 100%;
		max-width: 100% !important;
	}
	#tracking .linear_view .event .details .grid_2_col,
	#tracking-logged-in .linear_view .event .details .grid_2_col {
		grid-template-columns: 9rem auto;
	}
	.tracking-slider .track_bar {
		width: 97%;
	}
	.tracking-slider .top_row {
		flex-direction: column;
		align-items: flex-start;
	}
	.tracking-slider .top_row h4 {
		font-size: 1rem;
		margin-bottom: 1rem;
	}
	.tracking-slider .top_row .toggle_view button {
		font-size: 0.8rem;
	}
	.tracking-slider .track_bar .bar {
		height: 10px;
	}
	.tracking-slider .track_bar .point {
		height: 30px;
		width: 30px;
	}
	.tracking-slider .track_bar .point .label p {
		font-size: 0.7rem;
	}
}
@media (max-width: 600px) {
	.row_2_col {
		grid-template-columns: 1fr;
		gap: 2rem;
		text-align: center;
	}
	.row_2_col h2 {
		font-size: 1.8rem;
	}
	.row_2_col img {
		width: 80%;
		margin: auto;
	}
	#tracking .linear_view .event,
	#tracking-logged-in .linear_view .event {
		display: grid;
		grid-template-columns: auto 60px 1fr;
		gap: 2rem;
	}
	.tracking {
		margin: 4rem auto 1rem auto;
	}
	.tracking > p {
		font-size: 1rem;
	}
	.tracking .form_group {
		max-width: 100%;
	}
	.tracking_details .card {
		padding: 0.5rem;
	}
	.tracking_details .tracking-slider .track_bar .bar .label {
		font-size: 14px;
	}
}
@keyframes slideInFromLeft {
	from {
		transform: translateX(-100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slideInFromRight {
	from {
		transform: translateX(100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}
.slide-in-left {
	animation: slideInFromLeft 0.5s ease-out forwards;
}
.slide-in-right {
	animation: slideInFromRight 0.5s ease-out forwards;
}
@keyframes scaleAnimation {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	30% {
		transform: scale(0.9);
		opacity: 0.3;
	}
	100% {
		transform: scale(14) translateZ(200px);
		opacity: 0.2;
	}
}
.animate-scale {
	animation: scaleAnimation 1s ease-in-out forwards;
}
.overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	width: 95vw;
	height: 95vh;
	background-color: #21232e;
	border-radius: 0.375rem;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	z-index: 50;
	transform: translate(-50%, -50%);
}
.overlay.show {
	opacity: 1;
}
.map-button {
	cursor: pointer;
}
.semi-circle-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 200px;
	width: 200px;
	position: relative;
}
.oui-modal__box.large-modal {
	@apply h-[98vh] max-w-none z-[1000];
}
.oui-modal__box.mid-modal {
	@apply h-[80vh] max-w-none z-[1000];
}
.oui-modal__box.large-modal .oui-modal__description,
.oui-modal__box.mid-modal .oui-modal__description {
	@apply hidden;
}
.oui-modal__box.large-modal .oui-modal__controls,
.oui-modal__box.mid-modal .oui-modal__controls {
	@apply hidden;
}
.oui-modal__box.large-modal .oui-modal__close-button,
.oui-modal__box.mid-modal .oui-modal__close-button {
	@apply top-1 right-1;
}
.oui-modal__box.large-modal .oui-modal__body {
	@apply h-[90vh] max-h-none !overflow-hidden;
}
.oui-modal__box.mid-modal .oui-modal__body {
	@apply h-[75vh] max-h-none;
}
.oui-modal__box.large-modal .oui-scrollArea,
.oui-modal__box.mid-modal .oui-scrollArea {
	@apply overflow-hidden;
}
.oui-button--primary.mapAction {
	@apply text-[#FFFFFF] font-semibold bg-gradient-to-r from-[#FFB323] to-[#237BFF];
}
