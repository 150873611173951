body {
	background-color: #000 !important;
	height: 90vh !important;
	padding-bottom: 5vh;
	width: 100%;
	min-width: 100%;
}

.background-layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	overflow-x: clip;
}

.glow-up,
.right-glow,
.blue-glow,
.yellow-glow {
	position: absolute;
	background-size: cover;
	z-index: -1;
}

.glow-up {
	left: 5%;
	top: -650px;
	width: 1312px;
	height: 1080px;
	background-image: url(../../public/images/tracking/purple-glow.png);
}

/* .right-glow {
	right: -70px;
	top: -40px;
	width: 906px;
	background-image: url(../../public/images/tracking/hero-glow.png);
} */

/* .blue-glow {
	left: -480px;
	top: 340px;
	width: 1312px;
	height: 1080px;
	background-image: url(../../public/images/tracking/purple-glow.png);
} */

.yellow-glow {
	right: -70px;
	top: 1500px;
	width: 906px;
	height: 700px;
	background-image: url(../../public/images/tracking/yellow-glow.png);
}

.texture {
	position: absolute;
	opacity: 0.75;
	background-size: cover;
	z-index: -1;
}

.texture-b {
	width: 1366px;
	height: 453px;
	background-image: url(../../public/images/tracking/texture-a.png);
}

.texture-3 {
	left: 0;
	top: 860px;
}

.texture-4 {
	left: 0;
	top: 1750px;
}

div {
	display: block;
	unicode-bidi: isolate;
}

.glow-up {
	position: absolute;
	left: 5%;
	top: -650px;
	width: 1312px;
	height: 1080px;
	background-image: url(../../public/images/tracking/purple-glow.png);
	background-size: cover;
	z-index: -1;
}

.right-glow {
	position: absolute;
	right: -220px;
	top: -20px;
	width: 906px;
	background-image: url(../../public/images/tracking/hero-glow.png);
	background-size: cover;
	z-index: -1;
}

.blue-glow {
	position: absolute;
	left: -480px;
	top: 80px;
	width: 1312px;
	background-image: url(../../public/images/tracking/purple-glow.png);
	background-size: cover;
	z-index: -2;
}

.yellow-glow {
	position: absolute;
	right: -70px;
	top: 1500px;
	width: 906px;
	height: 700px;
	background-image: url(../../public/images/tracking/yellow-glow.png);
	background-size: cover;
	z-index: -1;
}

.texture {
	position: absolute;
	opacity: 0.75;
	background-size: cover;
	z-index: -1;
}

.texture-b {
	width: 1366px;
	height: 453px;
	background-image: url(../../public/images/tracking/texture-a.png);
}

.texture-3 {
	left: 0;
	top: 860px;
}

.texture-4 {
	left: 0;
	top: 1750px;
}

@media (max-width: 600px) {
	.yellow-glow {
		display: none;
	}
}
