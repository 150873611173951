.DetailedTrackingSection {
  position: relative;
  /* background: #e4e9f7; */
  min-height: 100vh;
  top: 20px;
  left: 75px;
  width: calc(100% - 78px);
  /* width: 100%; */

  transition: all 0.5s ease;
  z-index: 2;
  padding: 40px;
}
.flexbox {
  display: flex;
}
.leftBox {
  width: 50%;
}
.rightBox {
  width: 50%;
  margin-top: 20px;
}
.pod {
  width: 29vw;
  margin-bottom: 15px;
}

.cardContainer {
  width: 10%;
  border-radius: 8px;
  border: 1px solid #eff0f6;
  background: #fff;
  /* padding: 30px; */
  margin-top: 10px;
}
h2 {
  font-weight: 600;
}
.hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: #ccc;
  margin: 15px 0 10px;
}
.textContainer {
  width: 90%;
  margin: 15px 0;
}
.textFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.right {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  color: black;
}

.timelineFlexBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.outerCircle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 10px 0;
  position: relative;
  background-color: #dcdcdc;
}

.innerCircle {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: grey;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.circle + .circle {
  margin-top: 20px;
}

.title {
  color: #232323;
  font-size: 16px;
  font-weight: 700;
}
.details {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
}

.info {
  width: 18px;
  cursor: pointer;
}
.statusTextFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.statusText {
  display: flex;
  color: black;
}
.status {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 50%;
}


/* Style for the popup */
.popup {
  width: 35%;
  display: none;
  position: absolute;
  background-color: white;
  /* border: 1px solid #ddd; */
  padding: 15px 30px;
  border-radius: 5px;
  z-index: 100;
  /* Adjust the position as needed */
  top: 5%;
  left: 35%;

  filter: drop-shadow(4px 4px 18.9px rgba(0, 0, 0, 0.15));
}

/* Show popup when image is hovered */
.info:hover + .popup {
  display: block;
}

.popTextDate {
  color: #667085;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-bottom: 10px;
}
.popText {
  color: #667085;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.12px;
  margin-bottom: 10px;
}
