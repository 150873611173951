.underlineOffset {
    position: relative;
    text-decoration: none;
  }
  
  .underlineOffset::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: rgba(34, 148, 255, 1);
    bottom: -4px;
    left: 0;
  }