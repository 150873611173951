.saveOrderBtn {
  margin: 0 auto;
  width: 10vw;
  padding: 10px;
  background: #feb221;
  border-radius: 10px;
  color: white;
  font-family: 16px;
}

.section {
  position: relative;
  top: 0;
  height: 100%;
  /* left: 250px; */
  /* width: calc(100% - 78px); */
  transition: all 0.5s ease;
  z-index: 2;
  padding: 50px 20px 50px 10px;
  width: 100%;
  background: #f3f3f3;
}
.header {
  display: flex;
  justify-content: space-between;
}

.icon {
  width: 20px;
  height: 20px;
}
.hr {
  border: 0;
  height: 1px;
  width: 100%;
  background: #ccc;
  margin: 15px 0 10px;
}

.cardFullLength {
  width: 100%;
  height: 60vh;
  padding: 37px 43px;
  align-items: flex-start;
  border-radius: 20px;
  border: 1px solid var(--Grey, #dde2e5);
  background: #fff;
  margin-bottom: 10px;
}
.flexBox {
  display: flex;
  gap: 10px;
}

.inputFlex {
  display: flex;
  gap: 10px;
}

.left {
  width: 50%;
}

.right {
  width: 50%;
}

.label {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 500;
}
.input {
  width: 100%;
  border-radius: 8px;
  /* background: rgba(220, 220, 220, 0.6); */
  background: transparent;
  border: 1px solid rgb(241, 238, 238);
  font-size: 12px;
  font-weight: 500;
  padding: 10px 5px;
  color: black;
}

.inputFlexHalf {
  display: flex;
  width: 48%;
}
.button {
  padding: 10px 30px;
  border-radius: 22px;
  border: 1px solid #000;
  box-shadow: 2px 4px 4px 0px rgba(79, 42, 234, 0.17);
}

.screenshots {
  width: 100vw;
  height: 40vh;
  margin-bottom: 10px;
}

.fileUpload {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
  width: 100%;
}
.fileUpload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}
.fileUpload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.uploadIcon {
  width: 80px;
}
