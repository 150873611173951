.icon {
  width: 20px;
  margin: 5px;
}

.totalOrders {
  display: flex;
  align-items: center;
  gap: 1vw;
}

.quickInfoContainer {
  margin-top: 20px;
  display: flex;
  gap: 2vw;
}

.flexBox {
  display: flex;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: blue;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px; /* Adjust margin as needed */
}

.quickInfoH4 {
  margin-left: 19px;
  font-size: 18px;
  font-weight: 600;
}

.statusDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

/* Add more colors as needed */
.NotDispatched {
  background-color: #007aff;
}

.OnRoute {
  background-color: #faa808;
}

.ReceivedAtDestination {
  background-color: #3e8f40;
}

.Delayed {
  background-color: #fa2c2c;
}
