.drawerHeader {
  display: flex;
  justify-content: center;
}

.drawerHeader button {
  right: 24px;
}

.drawerBody {
  padding: 8px 24px 24px;
}
