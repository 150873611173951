.flexRow {
  text-align: center;
  display: flex;
  width: 100%;
  position: relative;
}

.flexRow:last-child {
  width: unset;
}

.circleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 40px;
}

.outerCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin: 10px 0;
  position: relative;
}

.innerCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  background-color: #008545;
  z-index: 9;
}

.line {
  width: 100%;
  height: 5px;
  background-color: #008545;
  position: absolute;
  top: 15px;
  left: 0;
  bottom: 0;
}

.grayLine {
  background-color: #d9d9d9;
}

.data {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  transform: translateX(-50%);
  width: max-content;
}

.flexRow:first-child .data {
  transform: none;
}

.flexRow:last-child .data {
  transform: translateX(-80%);
}
