.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--header-z-index, 500);
  padding: 27px 1.5rem;
  background-color: var(--chakra-colors-chakra-body-bg);
  background: linear-gradient(180deg, #33291e 0%, var(--app-background, #191921) 100%);
  height: var(--header-height);
}

/* .header {
  background-color: var(--chakra-colors-chakra-body-bg);
} */
