.flex {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.right {
  display: flex;
  gap: 20px;
  margin-right: 25px;
  margin-bottom: 20px;
}

.button {
  height: 8vh;
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: #fff;
}
.icon {
  width: 20px;
  margin: 5px;
}

.search {
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.searchForm {
  padding: 10px;
  border-radius: 30px;
  background: #fff;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
}

.search .input[type="text"] {
  width: 350px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #adadad;
  background: #f4f7fe;
}

.search .searchBtn[type="submit"] {
  border: none;
  color: #000000;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
}

.search .searchBtn[type="submit"]:hover {
  color: rgb(255, 255, 255);
}
