.div {
  border-radius: 8px;
  box-shadow: 0px 40px 58px -20px rgba(112, 144, 176, 0.12);
  background-color: #fff;
  display: flex;
  max-width: 385px;
  min-width: 0;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  color: #667085;
  padding: 32px 0 50px;
}
.div2 {
  border-radius: 50%;
  align-self: center;
  width: 71px;
  height: 71px;
}
.div3 {
  display: flex;
  margin-top: 27px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0 23px;
}
.div4 {
  background-color: #d9d9d9;
  align-self: stretch;
  height: 1px;
}
.div5 {
  color: #404040;
  letter-spacing: -0.48px;
  margin-top: 33px;
  white-space: nowrap;
  font:
    600 24px Montserrat,
    sans-serif;
}
.div6 {
  color: #404040;
  letter-spacing: -0.32px;
  margin-top: 22px;
  margin-bottom: 30px;
  white-space: nowrap;
  font:
    16px Montserrat,
    sans-serif;
}
.div7 {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  align-items: center;
  width: 90%;
}
.img {
  aspect-ratio: 1.28;
  object-fit: auto;
  object-position: center;
  width: 46px;
  align-self: start;
}
