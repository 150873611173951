.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  width: var(--navigation-width, 252px);
  box-shadow: 0px 0px 4px 0px rgba(91, 91, 91, 0.25);
  background-color: var(--chakra-colors-chakra-body-bg);
  padding: 20px 0px;
  z-index: var(--navigation-z-index, 750);
  padding-right: 12px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollBox {
  margin-top: 40px;
  overflow-y: auto;
  height: calc(100vh - 120px); /* fallback */
  height: calc(100dvh - 120px);
}

.links {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  background: transparent;
  border-radius: 0px 4px 4px 0px;
  padding: 12px 16px 12px 24px;
  border: 1px solid transparent;
  /* margin-bottom: 16px; */
  transition:
    background-color 0.1s ease,
    color 0.1s ease;
}

/* Hover Effect */
.links:hover,
.links:focus-visible {
  background-color: var(--navigation-bg-hover);
}

.links.active {
  background-color: var(--navigation-bg-hover);
  border: 1px solid #393a4b;
}

.links svg {
  width: 24px;
  height: 24px;
  text-align: center;
  margin-right: 12px;
}
